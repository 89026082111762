import { css } from '@emotion/react'
import styled from '@emotion/styled'
import React, {
  ChangeEventHandler,
  FocusEventHandler,
  forwardRef,
  memo,
} from 'react'

export interface Props {
  accept?: string
  color?: Color
  disabled?: boolean
  id?: string
  name: string
  type?: string
  placeholder?: string
  error?: boolean
  isDirty?: boolean
  required?: boolean
  variant?: Variant
  value?: string
  onBlur?: FocusEventHandler<HTMLInputElement>
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
}

export const Input = memo(
  forwardRef<HTMLInputElement, Props>(function Input(
    {
      accept,
      color = 'default',
      disabled,
      id,
      name,
      type,
      placeholder,
      error = false,
      isDirty = false,
      required,
      variant = 'text',
      value,
      onBlur,
      onChange,
      onFocus,
    },
    ref,
  ) {
    return (
      <Container
        ref={ref}
        accept={accept}
        color={color}
        error={error}
        id={id}
        isDirty={isDirty}
        disabled={disabled}
        name={name}
        type={type}
        placeholder={placeholder}
        required={required}
        variant={variant}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    )
  }),
)

Input.displayName = 'Input'

const Container = styled.input<ContainerProps>`
  ${({ theme, color, error, variant }) => {
    return css`
      :-webkit-autofill,
      :-webkit-autofill:hover,
      :-webkit-autofill:focus,
      :-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 100px ${theme.colors.variants.primaryDark}
          inset;
        -webkit-text-fill-color: ${theme.colors.variants.neutralLight3};
      }

      ::-webkit-outer-spin-button,
      ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      [type='number'] {
        -moz-appearance: textfield;
      }

      ${variant === 'submit'
        ? css`
            display: inline-block;
            height: 3.5rem;
            background: ${theme.colors.variants.secondaryLight};
            color: ${theme.colors.variants.neutralLight4};
            font-family: ${theme.fontFamily.paragraph};
            font-size: 0.875rem;
            font-weight: 500;
            letter-spacing: 0.0875rem;
            margin-top: 2rem;
            padding: 0 2.125rem;
            position: relative;
            text-align: center;
            text-transform: uppercase;
            transition: 0.3s ease-in-out;
            z-index: 2;
            &:hover {
              background: ${theme.colors.variants.secondaryDark};
            }
            &:disabled {
              background: ${theme.colors.variants.neutralDark5};
              cursor: not-allowed;
            }

            @media (max-width: 767px) {
              width: 100%;
            }
          `
        : color === 'default'
        ? css`
            width: 100%;
            height: 3.5rem;
            background: ${error
              ? theme.colors.variants.dangerLight
              : theme.colors.variants.neutralLight4};
            color: ${theme.colors.variants.neutralDark5};
            font-family: ${theme.fontFamily.paragraph};
            font-size: 0.75rem;
            font-weight: 500;
            padding: 0 1.5rem;

            &::-webkit-input-placeholder {
              color: ${error
                ? theme.colors.variants.neutralLight4
                : theme.colors.variants.neutralDark5};
              opacity: 1;
            }
            &::-moz-placeholder {
              color: ${error
                ? theme.colors.variants.neutralLight4
                : theme.colors.variants.neutralDark5};
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: ${error
                ? theme.colors.variants.neutralLight4
                : theme.colors.variants.neutralDark5};
              opacity: 1;
            }
            &::placeholder {
              color: ${error
                ? theme.colors.variants.neutralLight4
                : theme.colors.variants.neutralDark5};
              opacity: 1;
            }
          `
        : css`
            width: 100%;
            height: 3.5rem;
            background: ${error
              ? theme.colors.variants.dangerLight
              : theme.colors.variants.primaryDark};
            color: ${theme.colors.variants.neutralLight4};
            font-family: ${theme.fontFamily.paragraph};
            font-size: 0.75rem;
            font-weight: 500;
            padding: 0 1.5rem;

            &::-webkit-input-placeholder {
              color: ${theme.colors.variants.neutralLight4};
              opacity: 1;
            }
            &::-moz-placeholder {
              color: ${theme.colors.variants.neutralLight4};
              opacity: 1;
            }
            &::-ms-input-placeholder {
              color: ${theme.colors.variants.neutralLight4};
              opacity: 1;
            }
            &::placeholder {
              color: ${theme.colors.variants.neutralLight4};
              opacity: 1;
            }
          `}
    `
  }}
`

interface ContainerProps {
  color: Color
  error: boolean
  isDirty: boolean
  variant: Variant
}

export type Color = 'default' | 'dark'

export type Variant = 'text' | 'checkbox' | 'submit'
